import React from 'react';
import './App.css';
import Nooo from './assets/nooo.gif';
import LogRocket from 'logrocket';

LogRocket.init('7ryk0l/but-you-eat-fish-right');

function App() {
  return (
    <div className="App">
      <header className="App-header">
      …but you eat fish, right?
      {/*
      <p className="App-logo">
        No.
      </p>
      */}
      <img src={Nooo} alt="No." className="App-gif" />
      </header>
    </div>
  );
}

export default App;
